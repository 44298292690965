import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-custom-button-quantity',
    templateUrl: './custom-button-qty.component.html',
    styleUrls: ['./custom-button-qty.component.scss']
})
export class CustomButtonQtyComponent {
    @Output() getQuantity: EventEmitter<number> = new EventEmitter<number>();
    @Input() icon = '';
    @Input() label = '';

    value = 1;

    sendQuantity() {
        this.getQuantity.emit(this.value);
        this.value = 1;
    }
}
