import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromEquipmentInformation } from 'src/app/modules/equipments/store/equipment-information.selectors';
import { fromEquipments } from 'src/app/modules/equipments/store/equipments.selectors';
import { MenuTab } from 'src/app/modules/interfaces/menu-tab';
import { EquipmentDetail } from 'src/app/shared/interfaces/equipment.interface';
import { DEFAULT, fromRoot, SELECTED_EQUIPMENT_HEADER } from 'src/app/store/root.selectors';

@Component({
  selector: 'app-side-menu-header',
  templateUrl: './side-menu-header.component.html',
  styleUrls: ['./side-menu-header.component.scss']
})
export class SideMenuHeaderComponent {

  @Input() isRoot: boolean = false;
  @Input() collapsedMode: boolean = false;

  @Input() currentRoute!: MenuTab;


  mode$: Observable<string>;
  selectedEquipment$: Observable<EquipmentDetail | null>;

  SELECTED_EQUIPMENT_HEADER = SELECTED_EQUIPMENT_HEADER;
  DEFAULT = DEFAULT;

  constructor(private store: Store, private router: Router) {
    this.mode$ = this.store.select(fromRoot.selectSideMenuHeaderMode);
    this.selectedEquipment$ = this.store.select(fromEquipments.selectEquipment);
  }



  onNavigateToRoot() {
    this.router.navigate(['/']);
  }


  onNavigateToEquipmentList() {
    this.router.navigate([`app/equipments`], {
      queryParams: {
        selected: undefined
      }
    });
  }
}
