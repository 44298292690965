import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { Information } from '../interfaces/information.interface';
import { ResponseObject } from '../interfaces/response-object.interface';

@Injectable({
  providedIn: 'root'
})
export class InformationService {
  
  
  constructor(private apiService: ApiService) { }
  
  getAll(): Observable<ResponseObject<Information[]>> {
    return this.apiService.get(
      'information'
    );
  }

  downloadPdf(path: string) {
    const params = new HttpParams({
      fromObject: {
        path
      },
    });
    return this.apiService.get<Blob>(
      `information/download`,
      params,
      'blob'
    )
  }

  getEquipmentInformation(equipmentId: string): Observable<ResponseObject<Information[]>> {
    return this.apiService.get(
      `information/equipment/${equipmentId}`,
    );
  }
  downloadEquipmentPdf(equipmentId: string, path: string) {
    const params = new HttpParams({
      fromObject: {
        path
      },
    });
    return this.apiService.get<Blob>(
      `information/download/equipment/${equipmentId}`,
      params,
      'blob'
    )
  }

}
