import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
    styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent {
    message = '';
    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { onAccept: () => void }
    ) {}

    onDiscard(): void {
        this.dialogRef.close();
    }

    onAccept(): void {
        this.data.onAccept();
        this.dialogRef.close();
    }
}
