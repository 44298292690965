import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';


const DEFAULT_LABEL = 'ENGLISH';
const DEFAULT_CODE = 'en';
@Component({
  selector: 'app-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.scss']
})
export class LocaleSelectComponent implements OnInit {

  currentLocale: string = DEFAULT_LABEL;

  constructor(private translationService: TranslateService) {
    translationService.setDefaultLang(DEFAULT_CODE);
  }

  ngOnInit() {
    this.currentLocale = this.getLocaleLabel(localStorage.getItem(StorageKey.LANGUAGE) ?? DEFAULT_CODE);
    this.translationService.onLangChange.subscribe(() => {
      this.updateLocale();
    })
  }


  updateLocale() {
    this.currentLocale = this.getLocaleLabel(this.translationService.currentLang);
  }

  private getLocaleLabel(code: string): string {
    return this.localesList.find(locale => locale.code === code)?.label ?? DEFAULT_LABEL;
  }

  switchLocale(arg0: string) {
    this.translationService.use(arg0);
    this.showOptions = false;
    localStorage.setItem(StorageKey.LANGUAGE, arg0);
  }

  showOptions = false;

  showHide() {
    this.showOptions = !this.showOptions;
  }

  localesList = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'French' }
  ];
}
