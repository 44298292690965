import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CellContent } from '../../interfaces/list-view.interface';

@Component({
    selector: 'app-list-view-item',
    templateUrl: './list-view-item.component.html',
    styleUrls: ['./list-view-item.component.scss']
})
export class ListViewItemComponent {
    @Input() isHeader: boolean | undefined = false;
    @Input() cellContents: CellContent[] = [];
    @Input() proportions: number[] = [];
    @Output() clickOnCell: EventEmitter<number> = new EventEmitter<number>();

    setProportionsOnItem(): string {
        if (
            this.proportions.length > 0 &&
            this.cellContents.length === this.proportions.length
        ) {
            return this.proportions.map((n) => `${n}fr`).join(' ');
        } else if (
            !this.cellContents.some(
                (c) => c.proportionOnRow === null || c.proportionOnRow === undefined
            )
        ) {
            return this.cellContents.map((c) => `${c.proportionOnRow}fr`).join(' ');
        }
        return this.cellContents.map(() => `1fr`).join(' ');
    }

    onClick(cellIndex: number) {
        this.clickOnCell.emit(cellIndex);
    }
}
