import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsService } from './services/notifications.service';
import { ToastrModule } from 'ngx-toastr';
import { LocalStorageService } from './services/local-storage.service';
import { AuthService } from './services/auth.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, ToastrModule.forRoot()]
})
export class CoreModule {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CoreModule,
            providers: [NotificationsService, LocalStorageService, AuthService]
        };
    }
}
