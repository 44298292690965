<div class="center-tab-container">
    <ng-container *ngIf="showOptions">
        <div
            *ngIf="{ currentCenter: currentCenter$ | async } as data"
            class="opt-container option active"
            [title]="data.currentCenter?.description"
            (click)="showHide()"
        >
            <mat-icon svgIcon="factory-active"></mat-icon>
            <div class="text">{{ data.currentCenter?.description }}</div>
        </div>
        <div class="divider"></div>
        <div class="center-container scroll-grid">
            <span class="header">{{'Change Factory' | translate }}</span>
            <div class="body">
                <div
                    class="opt-container option"
                    *ngFor="let item of centers$ | async; index as i; trackBy: trackByFn"
                    [title]="item.description"
                    (click)="changeCenter(item)"
                >
                    <mat-icon svgIcon="factory"></mat-icon>
                    <div class="text">{{ item.description }}</div>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="currentCenter$ | async as currentCenter; else skeleton">
        <div
            [title]="currentCenter.description"
            class="option opt-container"
            *ngIf="!showOptions"
            (click)="showHide()"
        >
            <mat-icon svgIcon="factory"></mat-icon>
            <div class="text">{{ currentCenter.description }}</div>
        </div>
    </div>

    <ng-template #skeleton>
        <div class="option skeleton">
            <ngx-skeleton-loader count="1" appearance="circle" animation="pulse">
            </ngx-skeleton-loader>
            <div>
                <ngx-skeleton-loader count="1" appearance="line" animation="pulse">
                </ngx-skeleton-loader>
            </div>
        </div>
    </ng-template>
</div>
