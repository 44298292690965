import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private keycloakService: KeycloakService) {}

    login(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.keycloakService
                .login()
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    logout(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.keycloakService
                .logout()
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(false);
                });
        });
    }

    getUserInfo(): Promise<KeycloakProfile> {
        return this.keycloakService.loadUserProfile();
    }

    getToken(): Promise<string> {
        return this.keycloakService.getToken();
    }

    isUserLoggedIn(): Promise<boolean> {
        return this.keycloakService.isLoggedIn();
    }

    isUserInRole(role: string, resource?: string): boolean {
        return this.keycloakService.isUserInRole(role, resource);
    }

    getUserRoles(allRoles?: boolean): string[] {
        return this.keycloakService.getUserRoles(allRoles);
    }
}
