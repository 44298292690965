import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SideMenuNavigationComponent } from './side-menu-navigation.component';
import { authGuard } from './core/guards/auth.guard';

const routes: Routes = [
    {
        path: 'app',
        component: SideMenuNavigationComponent,
        canActivate: [authGuard],
        canActivateChild: [authGuard],
        children: [
            {
                path: 'home',
                canActivate: [authGuard],
                loadChildren: () =>
                    import('./modules/home/home.module').then((m) => m.HomeModule)
            },
            {
                path: 'equipments',
                canActivate: [authGuard],
                loadChildren: () =>
                    import('./modules/equipments/equipments.module').then(
                        (m) => m.EquipmentsModule
                    )
            },
            {
                path: 'information',
                canActivate: [authGuard],
                loadChildren: () =>
                    import('./modules/information/information.module').then(
                        (m) => m.InformationModule
                    )
            },
            {
                path: 'carts',
                canActivate: [authGuard],
                loadChildren: () =>
                    import('./modules/cart/cart.module').then((m) => m.CartModule)
            }
        ]
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./modules/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            )
    },
    {
        path: '',
        redirectTo: 'app/home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'app/home'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
