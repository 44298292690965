<div class="control-panel-container">
    <div class="settings-buttons-container">
      <app-custom-button
        svgIcon="device_reset"
        color="primary"
        [text]="'Reset' | translate"
        [disabled]="!loaded"
        [borderRadius]="25"
        [styles]="{
                        'border': '1px solid #79747E'
                    }"
        (buttonClick)="onReset()"
      ></app-custom-button>
      <app-custom-button
        svgIcon="disabled_visible"
        color="primary"
        [text]="'Hide Part' | translate"
        [disabled]="!loaded"
        [isToggle]="true"
        [isActive]="hidePartsActive"
        [hasReturn]="true"
        [borderRadius]="25"
        [styles]="{
                        'border': '1px solid #79747E'
                    }"
        (buttonClick)="onHidePart()"
        (returnClick)="onHidePartUndo()"
      ></app-custom-button>
      <app-custom-button
        svgIcon="grain"
        color="primary"
        [text]="'Hotspots' | translate"
        [disabled]="!loaded"
        [isToggle]="true"
        [isActive]="showHotspotsActive"
        [borderRadius]="25"
        [styles]="{
                        'border': '1px solid #79747E'
                    }"
        (buttonClick)="onShowHotspots()"
      ></app-custom-button>
    </div>
    <div class="sliders-container">
      <app-custom-slider
        svgIcon='explosion'
        [label]="'Explode' | translate"
        color="var(--primary-color)"
        [disabled]="!loaded"
        [value]="explodeFactor"
        [bold]="true"
        (valueChange)="onExplodeValueChange($event)"
      ></app-custom-slider>
      <app-custom-slider
        svgIcon="visibility"
        [label]="'Visibility' | translate"
        color="var(--primary-color)"
        [disabled]="!loaded"
        [value]="opacityFactor"
        [minValue]="5"
        [bold]="true"
        (valueChange)="onOpacityValueChange($event)"
      ></app-custom-slider>
    </div>
  </div>