<table mat-table *ngIf="dataSource.length; else emptyState" [dataSource]="dataSource">
    <!-- Name Column -->
    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
        <td mat-cell *matCellDef="let item">
            <span class="name">
                <mat-icon class="picture_as_pdf" [svgIcon]="item.name | fileIcon"></mat-icon>{{ item.name }}
            </span>
        </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef>{{ 'Date' | translate }}</th>
        <td mat-cell *matCellDef="let item">{{ item.date | date }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="view">
            <div class="actions-container">
                <a [class.hidden]="!showClick(item)" (click)="onGo(item)">{{ 'View' | translate }}</a>
                <button
                    mat-icon-button
                    (click)="onDownload(item)"
                    [title]="'Download' | translate"
                >
                    <mat-icon svgIcon="download"></mat-icon>
                </button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<ng-template #emptyState>
    <div class="empty-state">
        <p>No data available</p>
    </div>
</ng-template>
