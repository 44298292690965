import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
    const pathsToExcludeFromAuth = [
        ...environment.config.excludeAuth,
        '/assets',
        '/clients/public'
    ];
    return () => {
        return keycloak.init({
            config: {
                ...environment.config.keycloak
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/auth/silent-check-sso.html'
            },
            shouldAddToken: (request) => {
                const { method, url } = request;
                const isGetRequest = 'GET' === method.toUpperCase();
                const isAcceptablePathMatch = pathsToExcludeFromAuth.some((path) =>
                    url.includes(path)
                );
                return !(isGetRequest && isAcceptablePathMatch);
            }
        });
    };
}
