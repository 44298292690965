<ng-container *ngIf="{ mode: mode$ | async } as data">
    <ng-container *ngIf="!isRoot && data.mode == DEFAULT">
        <app-custom-button
            [text]="collapsedMode ? undefined : ('Back to home' | translate)"
            icon="arrow_back_ios_new"
            type="link"
            (buttonClick)="onNavigateToRoot()"
            class="back-button"
            [class.justify-center]="false"
        ></app-custom-button>
        <app-side-menu-tab
            [disableLink]="true"
            [menuTab]="currentRoute"
        ></app-side-menu-tab>
    </ng-container>

    <ng-container *ngIf="!isRoot && data.mode == SELECTED_EQUIPMENT_HEADER">
        <app-custom-button
            [text]="collapsedMode ? undefined : ('Back to equipments' | translate)"
            icon="arrow_back_ios_new"
            type="link"
            (buttonClick)="onNavigateToEquipmentList()"
            class="back-button"
            [class.justify-center]="false"
        ></app-custom-button>
        <div class="equipment-info-container">
            <div
                id="txt-item"
                *ngIf="selectedEquipment$ | async as equipment; else skeleton"
                class="text-item-container"
            >
                <div class="main-text">
                    <mat-icon>construction</mat-icon>
                    <span class="text1" [title]="equipment.number">{{
                        equipment.number
                    }}</span>
                    <span class="text2" [title]="equipment.name">{{
                        equipment.name
                    }}</span>
                </div>
                <div class="secondary-text" [title]="equipment.description">
                    {{ equipment.description }}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #skeleton>
    <div class="skeleton-selected">
        <ngx-skeleton-loader count="2" appearance="line" animation="pulse">
        </ngx-skeleton-loader>
    </div>
</ng-template>
