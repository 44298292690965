<div
    class="list-view-item-container"
    [ngStyle]="{ 'grid-template-columns': setProportionsOnItem() }"
>
    <ng-container *ngFor="let cellContent of cellContents; let cellIndex = index">
        <div
            id="cell"
            [class.header]="isHeader"
            [class.description]="!isHeader && cellContent.isDescription"
            [class.link]="!isHeader && cellContent.isLink"
            class="grid-item"
            (click)="onClick(cellIndex)"
        >
            {{ cellContent.content | translate }}
        </div>
    </ng-container>
</div>
