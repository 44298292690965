import { Equipment } from 'src/app/shared/interfaces/equipment.interface';
import { SubMenuTab } from '../../interfaces/menu-tab';
import { QuoteSummary } from 'src/app/shared/interfaces/quote-summary.interface';
import { formatDate } from '@angular/common';

export function transformEquipmentIntoSubTab(equipment: Equipment): SubMenuTab {
    return {
        id: equipment.id,
        title: `${equipment.name} ${equipment.number}`,
        description: equipment.description
    };
}

export function transformQuoteIntoSubTab(quoteSummary: QuoteSummary): SubMenuTab {
    return {
        id: quoteSummary.id,
        title: formatDate(quoteSummary.createdAt, 'yyyy-MM-dd', 'en'),
        description:
            quoteSummary.itemsCount > 1
                ? `${quoteSummary.itemsCount} items`
                : `${quoteSummary.itemsCount} item`
    };
}
