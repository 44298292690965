import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceService } from '../../../../core/services/device.service';
import { DeviceType } from '../../../../core/enums/device-type.enum';
import { Store } from '@ngrx/store';
import {
    getCenters
} from 'src/app/store/root.actions';
import { Route } from 'src/app/core/enums/routes.enum';
import { selectCurrentFeature } from 'src/app/store/root.selectors';


@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
    @Output() headerTabClick = new EventEmitter();
    isMenuOpen = false;
    expanded = false;
    prevScrollpos = 0;
    keepShowToolbarState = true;

    isMobile = this.deviceService.isMobile();
    isTablet = this.deviceService.isTablet();

    isComputer = false;

    subscriptions: Subscription[] = [];

    currentRoute$: Observable<Route>;

    constructor(
        private deviceService: DeviceService,
        private router: Router,
        private store: Store
    ) {
        this.currentRoute$ = this.store.select(selectCurrentFeature);
    }

    ngOnInit(): void {
        this.store.dispatch(getCenters());
        this.subscriptions.push(
            this.deviceService.currentDevice().subscribe((deviceType: DeviceType) => {
                this.isMobile = deviceType == DeviceType.MOBILE;
                this.isTablet = deviceType == DeviceType.TABLET;
                this.isComputer = deviceType == DeviceType.COMPUTER;
            })
        );
    }

    toggleExpanded(value: boolean): void {
        this.expanded = value;
    }

    onScroll(event: Event): boolean {
        if (this.isMobile) {
            this.prevScrollpos = (event.target as Element).scrollTop;
        }
        return false;
    }

    toggleIsMenuOpened(value: boolean) {
        this.isMenuOpen = value;
    }

    onNavigation() {
        this.toggleExpanded(false);
        this.toggleIsMenuOpened(false);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
