import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard = async (): Promise<boolean> => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const userIsLogged = await authService.isUserLoggedIn();
    if (!userIsLogged) {
        router.navigateByUrl('login');
    }
    return userIsLogged;
};
