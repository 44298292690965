import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface HttpOptions {
    headers?: HttpHeaders;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    responseType?: any;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    BASE_URL: string;

    constructor(private http: HttpClient) {
        const config = environment.config;
        this.BASE_URL = `${config.backendScheme}${config.backendUrl}/${config.backendUrlVersion}/`;
    }

    get<T>(url: string, params?: HttpParams, responseType = 'json'): Observable<T> {
        return this.http.get<T>(
            this.getFullUrl(url),
            this.getOptions(params, responseType)
        );
    }

    customGet<T>(url: string, params?: HttpParams, responseType = 'json'): Observable<T> {
        return this.http.get<T>(url, this.getOptions(params, responseType));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post<T>(url: string, body?: any, params?: HttpParams): Observable<T> {
        return this.http.post<T>(this.getFullUrl(url), body, this.getOptions(params));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patch<T>(url: string, body?: any, params?: HttpParams): Observable<T> {
        return this.http.patch<T>(this.getFullUrl(url), body, this.getOptions(params));
    }

    delete<T>(url: string, params?: HttpParams): Observable<T> {
        return this.http.delete<T>(this.getFullUrl(url), this.getOptions(params));
    }

    private getFullUrl(url: string): string {
        return `${this.BASE_URL}${url}`;
    }

    private getOptions(params?: HttpParams, responseType = 'json'): HttpOptions {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: params,
            responseType
        };
        return options;
    }
}
