<div class="list-view-container">
    <span class='title'>{{title}}</span>
    <div class="list-grid">
        <ng-container *ngFor="let listItem of listData; let itemIndex = index">
            <app-list-view-item
                [isHeader]="listItem?.isHeader"
                [cellContents]="listItem.cells"
                (clickOnCell)="handleClickOnCell(itemIndex, $event)"
            ></app-list-view-item>
        </ng-container>
    </div>
</div>
