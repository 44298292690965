import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Center } from 'src/app/shared/interfaces/center.interface';
import { changeCenter } from 'src/app/store/root.actions';
import { selectCenters, selectCurrentCenter } from 'src/app/store/root.selectors';

@Component({
    selector: 'app-center-manager',
    templateUrl: './center-manager.component.html',
    styleUrls: ['./center-manager.component.scss']
})
export class CenterManagerComponent {
    centers$: Observable<Center[] | null>;
    currentCenter$: Observable<Center | null>;

    constructor(private store: Store) {
        this.centers$ = this.store.select(selectCenters);
        this.currentCenter$ = this.store.select(selectCurrentCenter);
    }

    showOptions = false;

    showHide() {
        this.showOptions = !this.showOptions;
    }

    changeCenter(center: Center) {
        this.store.dispatch(changeCenter({ center }));
        this.showHide();
    }

    trackByFn(index: number, item: Center) {
        return item.id;
    }
}
