import { equipmentsFeature } from './equipments.reducers';

const {
    selectEquipmentLoading,
    selectEquipment,
    selectActivePart,
    selectModelLoading,
    selectModelUrl,
    selectPartQuantitiesInCart
} = equipmentsFeature;

export const fromEquipments = {
    selectEquipmentLoading,
    selectEquipment,
    selectActivePart,
    selectModelLoading,
    selectModelUrl,
    selectPartQuantitiesInCart
}

export {
    selectEquipmentLoading,
    selectEquipment,
    selectActivePart,
    selectModelLoading,
    selectModelUrl,
    selectPartQuantitiesInCart
};
