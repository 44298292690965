<button
    mat-mini-fab
    aria-label="Decrease quantity"
    (mousedown)="startDecreasing()"
    (mouseup)="stop()"
    (mouseleave)="stop()"
>
    <mat-icon>remove</mat-icon>
</button>
<input type="number" [(ngModel)]="quantity" (change)="onChange($event)" />
<button
    mat-mini-fab
    aria-label="Increase quantity"
    (mousedown)="startIncreasing()"
    (mouseup)="stop()"
    (mouseleave)="stop()"
>
    <mat-icon>add</mat-icon>
</button>
