import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { ResponseObject } from '../interfaces/response-object.interface';
import { Cart, CartItemBase } from '../interfaces/cart.interface';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    constructor(private apiService: ApiService) {}

    getCart(): Observable<ResponseObject<Cart>> {
        return this.apiService.get('cart');
    }

    updateCart(
        cartItem: CartItemBase,
        shouldOverwriteQuantity = true
    ): Observable<ResponseObject<boolean>> {
        return this.apiService.patch('cart', { ...cartItem, shouldOverwriteQuantity });
    }

    clearCart(cartId: string): Observable<ResponseObject<boolean>> {
        return this.apiService.post('cart/clear/' + cartId);
    }

    getCartCount(): Observable<ResponseObject<number>> {
        return this.apiService.get('cart/count/');
    }
}
