import { Injectable } from '@angular/core';
import { Equipment, EquipmentDetail, ModelUrl } from '../interfaces/equipment.interface';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { ResponseObject } from '../interfaces/response-object.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EquipmentService {
    constructor(private apiService: ApiService) {}

    getEquipments(query?: string): Observable<ResponseObject<Equipment[]>> {
        return this.apiService.get(
            'equipments',
            new HttpParams({ fromObject: { query: query ?? '' } })
        );
    }

    getEquipmentById(id: string): Observable<ResponseObject<EquipmentDetail>> {
        return this.apiService.get(`equipments/${id}`);
    }

    get3dModelForEquipmentSecureUrl(
        equipment: EquipmentDetail
    ): Observable<ResponseObject<ModelUrl>> {
        return this.apiService.get(`equipments/${equipment.id}/3d-file`);
    }

    get3dModelForEquipment(url: string): Observable<Blob> {
        return this.apiService.customGet(url, undefined, 'blob');
    }
}
