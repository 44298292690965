import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { Center } from '../interfaces/center.interface';
import { ResponseObject } from '../interfaces/response-object.interface';

@Injectable({
    providedIn: 'root'
})
export class CenterService {
    constructor(private apiService: ApiService) {}

    getCenters(): Observable<ResponseObject<Center[]>> {
        return this.apiService.get('center');
    }
}
