import { Injectable } from '@angular/core';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Equipment } from '../interfaces/equipment.interface';
import { ListItem } from '../interfaces/list-view.interface';

@Injectable({
    providedIn: 'root'
})
export class RecentEquipmentsService {
    recentEquipmentsLimit = 10;
    constructor(private localStorageService: LocalStorageService) {}

    getRecentEquipments(count: number): Equipment[] {
        const result = this.localStorageService.get<Equipment[]>(
            StorageKey.RECENT_EQUIPMENTS
        ) as Equipment[]

        if (!result) {
            return [];
        }

        return result.slice(0, count);
    }

    addToRecentEquipments(equipment: Equipment) {
        let recentEquipments: Equipment[] = this.getRecentEquipments(this.recentEquipmentsLimit);
        if (!recentEquipments) {
            recentEquipments = [];
        }
        const index = recentEquipments.findIndex((e) => e.id === equipment.id);
        if (index < 0) {
            if (recentEquipments.length === this.recentEquipmentsLimit) {
                recentEquipments.pop();
                recentEquipments = [equipment, ...recentEquipments];
            } else {
                recentEquipments = [equipment, ...recentEquipments];
            }
        }
        this.localStorageService.set(StorageKey.RECENT_EQUIPMENTS, recentEquipments);
    }


    getRecentEquipmentData(count: number): ListItem[] {
        const recentEquipments = this.getRecentEquipments(count);
        if (recentEquipments) {
            const header: ListItem = {
                id: '_header',
                isHeader: true,
                cells: [
                    {
                        content: 'MFT',
                        proportionOnRow: 2
                    },
                    {
                        content: 'No.',
                        proportionOnRow: 2
                    },
                    {
                        content: 'Description',
                        proportionOnRow: 3
                    },
                    {
                        content: '',
                        proportionOnRow: 1
                    }
                ]
            };
            return [
                header,
                ...recentEquipments.map((e) =>
                    this.transformEquipmentIntoListItem(e)
                )
            ];
        }
        return [];
    }

    transformEquipmentIntoListItem(equipment: Equipment): ListItem {
        return {
            id: equipment.id,
            cells: [
                {
                    content: equipment.name,
                    proportionOnRow: 2
                },
                {
                    content:
                        equipment.number.startsWith('(') && equipment.number.endsWith(')')
                            ? equipment.number.slice(1, -1)
                            : equipment.number,
                    proportionOnRow: 2
                },
                {
                    content: equipment.description,
                    isDescription: true,
                    proportionOnRow: 3
                },
                {
                    content: 'View',
                    isLink: true,
                    proportionOnRow: 1
                }
            ]
        };
    }
}
