import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-model-control-panel',
    templateUrl: './model-control-panel.component.html',
    styleUrls: ['./model-control-panel.component.scss']
})
export class ModelControlPanelComponent {
    @Input() loaded = false;
    @Input() opacityFactor = 100;
    @Input() explodeFactor = 0;
    @Input() showHotspotsActive = false;
    @Input() hidePartsActive = false;

    @Output() resetButtonClicked = new EventEmitter();
    @Output() hidePartButtonClicked = new EventEmitter();
    @Output() hidePartUndoButtonClicked = new EventEmitter();
    @Output() showHotspotsButtonClicked = new EventEmitter();
    @Output() explodeSliderChange = new EventEmitter<number>();
    @Output() opacitySliderChange = new EventEmitter<number>();

    onReset() {
        this.resetButtonClicked.emit();
    }

    onHidePart() {
        this.hidePartButtonClicked.emit();
    }

    onHidePartUndo() {
        this.hidePartUndoButtonClicked.emit();
    }

    onShowHotspots() {
        this.showHotspotsButtonClicked.emit();
    }

    onExplodeValueChange(value: number) {
        this.explodeSliderChange.emit(value);
    }

    onOpacityValueChange(value: number) {
        this.opacitySliderChange.emit(value);
    }
}
