import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Route } from 'src/app/core/enums/routes.enum';
import { MenuTab } from 'src/app/modules/interfaces/menu-tab';
import { getCartCount } from 'src/app/store/root.actions';
import { selectCartCount } from 'src/app/store/root.selectors';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit{

  CART_ROUTE = {
    title: 'Active Cart',
    id: Route.cart,
    routerLink: 'carts/active-cart',
    icon: 'shopping_cart_checkout',
    isSvgIcon: false,
    isActive: false
  }


  HISTORY_ROUTE = {
    title: 'History',
    id: Route.quotes,
    icon: 'request_quote',
    isSvgIcon: false,
    isActive: false
  }

  cartCount$ = this.store.select(selectCartCount);
  
  constructor(
    private store: Store
  ) {
    
  }
  
  ngOnInit(): void {
    this.store.dispatch(getCartCount());
  }

}
