import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Action } from '../../interfaces/action';
import { ButtonSize, ButtonType } from '../../types/button.type';
import { ThemeColor } from '../../types/theme-color.type';

@Component({
    selector: 'app-custom-button',
    templateUrl: './custom-button.component.html',
    styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent {
    @Input() text?: string;
    @Input() icon?: string;
    @Input() svgIcon?: string;
    @Input() type: ButtonType = 'basic';
    @Input() size: ButtonSize = 'auto';
    @Input() color?: ThemeColor;
    @Input() fontColorMode?: 'light';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() styles?: any;
    @Input() buttonContainer = false;
    @Input() iconPosition: 'left' | 'right' = 'left';
    @Input() showButton = true;
    @Input() disabled = false;
    @Input() stopPropagation = true;

    /**
     * @description It will anable a second button in the same button instance that allows to show a menu,
     * together with the definition of this input you shoul define the actions that you want to trigger
     * @type {boolean}
     * @example "true/false"
     */
    @Input() enableSecondButton = false;
    @Input() actions: Action[] = [];

    @Input() cursorStyle = '';
    @Input() borderRadius = 5;
    @Input() isToggle = false;
    @Input() isActive = false;
    @Input() hasReturn = false;
    @Output() buttonClick = new EventEmitter();
    @Output() returnClick = new EventEmitter();

    get buttonType(): string {
        if (this.type === 'basic') return 'mat-button';
        if (this.type === 'squared') return 'mat-stroked-button';
        return `mat-${this.type}-button`;
    }

    get blueHover(): boolean {
        return !this.disabled && this.type === 'stroked';
    }

    get isRoundIconButton(): boolean {
        return this.type !== 'squared' && !this.text && !!(this.svgIcon ?? this.icon);
    }

    get fontColor(): string | undefined {
        if (this.fontColorMode === 'light') return '#FFFFFF';
        return undefined;
    }


    getStyles(): { [key: string]: string } {
        let styles: { [key: string]: string } = {};
        if (this.styles) styles = { ...this.styles };
        if (this.isToggle) {
            styles = {
                ...styles,
                color: this.isActive ? 'white' : `var(--${this.color}-color)`,
                backgroundColor: this.isActive ? `var(--${this.color}-color)` : 'white'
            };
        }
        if (this.hasReturn) {
            styles = {
                ...styles,
                paddingLeft: '48px'
            };
        }
        return styles;
    }

    onClick(event: Event): void {
        if (this.stopPropagation) event.stopImmediatePropagation();
        if (this.disabled) return;
        this.isActive = !this.isActive;
        this.buttonClick.emit();
    }

    onClickReturn(event: Event) {
        if (this.stopPropagation) event.stopImmediatePropagation();
        if (this.disabled) return;
        this.returnClick.emit();
    }
}
