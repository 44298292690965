import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateField'
})
export class TranslateFieldPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  // we have current language here because there is no way 
  // to refresh a pipe if not like this
  transform(item: any, field: string, currentLanguage: string): string {
    if (!item) {
      return '';
    }

    const currentLang = this.translateService.currentLang || this.translateService.defaultLang;
    const prefix = currentLang === 'fr' ? 'french' : 'english';
    const langField = prefix + this.capitalize(field);

    if (item[langField] === undefined) {
      console.warn(`Field ${langField} not found in object`, item);
      return '';
    }

    return item[langField] || '';
  }

  private capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


}
