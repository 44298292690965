import { Injectable } from '@angular/core';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Equipment } from '../interfaces/equipment.interface';

@Injectable({
    providedIn: 'root'
})
export class RecentEquipmentsService {
    private recentEquipmentsLimit = 3;
    constructor(private localStorageService: LocalStorageService) {}

    getRecentEquipments(): Equipment[] {
        return this.localStorageService.get<Equipment[]>(
            StorageKey.RECENT_EQUIPMENTS
        ) as Equipment[];
    }

    addToRecentEquipments(equipment: Equipment) {
        let recentEquipments: Equipment[] = this.getRecentEquipments();
        if (!recentEquipments) {
            recentEquipments = [];
        }
        const index = recentEquipments.findIndex((e) => e.id === equipment.id);
        if (index < 0) {
            if (recentEquipments.length === this.recentEquipmentsLimit) {
                recentEquipments.pop();
                recentEquipments = [equipment, ...recentEquipments];
            } else {
                recentEquipments = [equipment, ...recentEquipments];
            }
        }
        this.localStorageService.set(StorageKey.RECENT_EQUIPMENTS, recentEquipments);
    }
}
