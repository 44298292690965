import { createFeature, createReducer, on } from '@ngrx/store';
import { Equipment } from '../shared/interfaces/equipment.interface';
import * as RootActions from './root.actions';
import { QuoteSummary } from '../shared/interfaces/quote-summary.interface';
import { Center } from '../shared/interfaces/center.interface';
import { Information } from '../shared/interfaces/information.interface';

export interface RootState {
    toolbarText: string;
    equipments: Equipment[];
    loadingEquipments: boolean;
    loggedIn: boolean;
    userName: string;
    quoteList: QuoteSummary[];
    centers: Center[] | null;
    currentCenter: Center | null;
    cartCount: number;
    infomationList: Information[];
    loadingInformation: boolean;
}

export const initialState: RootState = {
    toolbarText: '',
    equipments: [],
    loadingEquipments: false,
    loggedIn: false,
    userName: '',
    quoteList: [],
    centers: null,
    currentCenter: null,
    cartCount: 0,
    infomationList: [],
    loadingInformation: false
};
export const rootFeatureKey = 'root';
export const rootReducer = createReducer(
    initialState,
    on(RootActions.setToolbarText, (state: RootState, { text }) => {
        return {
            ...state,
            toolbarText: text
        };
    }),
    on(RootActions.filterEquipmentById, (state: RootState) => {
        return {
            ...state,
            equipments: [],
            loadingEquipments: true
        };
    }),
    on(RootActions.getAllEquipmentsSuccess, (state: RootState, { equipments }) => {
        return {
            ...state,
            equipments: equipments,
            loadingEquipments: false
        };
    }),
    on(RootActions.getAllEquipmentsFailure, (state: RootState) => {
        return {
            ...state,
            equipments: [],
            loadingEquipments: false
        };
    }),
    on(RootActions.loginKeycloakRequest, (state: RootState) => {
        return {
            ...state,
            loggedIn: false
        };
    }),
    on(RootActions.loginSuccess, (state: RootState) => {
        return {
            ...state,
            loggedIn: true
        };
    }),
    on(RootActions.loginFailure, (state: RootState) => {
        return {
            ...state,
            loggedIn: false
        };
    }),
    on(RootActions.logoutSuccess, (state: RootState) => {
        return {
            ...state,
            loggedIn: false
        };
    }),
    on(RootActions.getUserInfoSuccess, (state: RootState, { userName }) => {
        return {
            ...state,
            userName
        };
    }),
    on(RootActions.getQuoteListSuccess, (state: RootState, { quoteList }) => {
        return {
            ...state,
            quoteList
        };
    }),
    on(RootActions.getQuoteListFailure, (state: RootState) => {
        return {
            ...state,
            quoteList: []
        };
    }),
    on(RootActions.getQuoteList, (state: RootState) => {
        return {
            ...state,
            quoteList: []
        };
    }),
    on(RootActions.getCenters, (state: RootState) => {
        return {
            ...state,
            centers: null
        };
    }),
    on(RootActions.getCentersSuccess, (state: RootState, { centers }) => {
        return {
            ...state,
            centers
        };
    }),
    on(RootActions.changeCenterSuccess, (state: RootState, { center }) => {
        return {
            ...state,
            currentCenter: center
        };
    }),
    on(RootActions.setCenterFromLocalStorageSucess, (state: RootState, { center }) => {
        return {
            ...state,
            currentCenter: center
        };
    }),
    on(RootActions.getCartCount, (state: RootState) => {
        return {
            ...state,
            cartCount: 0
        };
    }),
    on(RootActions.getCartCountSuccess, (state: RootState, { count }) => {
        return {
            ...state,
            cartCount: count
        };
    }),
    on(RootActions.addPartToCartSuccess, (state: RootState, { count }) => {
        if (count < 0) 
            throw new Error('Invalid count');
        
        return {
            ...state,
            cartCount: state.cartCount + count
        };
    }),
    on(RootActions.substractPartToCartSuccess, (state: RootState, { count }) => {
        if (count < 0) 
            throw new Error('Invalid count');
        
        return {
            ...state,
            cartCount: state.cartCount - count
        };
    }),
    on(RootActions.getAllInformation, (state: RootState) => {
        return {
            ...state,
            infomationList: [],
            loadingInformation: true
        };
    }),
    on(RootActions.getAllInformationSuccess, (state: RootState, { information }) => {
        return {
            ...state,
            infomationList: information,
            loadingInformation: false
        };
    }),
    on(RootActions.getAllInformationFailure, (state: RootState) => {
        return {
            ...state,
            infomationList: [],
            loadingInformation: false
        };
    }),
);

export const rootFeature = createFeature({
    name: rootFeatureKey,
    reducer: rootReducer
});
