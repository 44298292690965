import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    constructor(private toastr: ToastrService) {
        this.toastr.toastrConfig.positionClass = 'toast-top-right';
        this.toastr.toastrConfig.enableHtml = true;
    }

    success(message: string, duration = 3000) {
        this.toastr.success(message, '', {
            timeOut: duration,
            closeButton: true
        });
    }

    info(message: string, duration = 3000): void {
        this.toastr.info(message, '', {
            timeOut: duration
        });
    }

    warning(message: string, duration = 3000): void {
        this.toastr.warning(message, '', {
            timeOut: duration,
            closeButton: true
        });
    }
    error(message: string, duration = 3000): void {
        this.toastr.error(message, '', {
            timeOut: duration
        });
    }
}
