<div
    class="subTab-container"
    >
    <!-- appScrollShadows
    appAutoScrollToSelected
    [selectedId]="selectedTabId$ | async"
    [items$]="quotes$" -->
    <app-text-item
        *ngFor="let quote of quotes$ | async; trackBy: trackByFn"
        [mainText1]="quote.title"
        [description]="quote.description"
        [isSelected]="quote.id === (selectedTabId$ | async)"
        (subTabSelected)="handleQuoteSelection(quote)"
    ></app-text-item>
</div>
