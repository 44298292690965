import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { StorageKey } from '../enums/storage-key.enum';
import { Center } from 'src/app/shared/interfaces/center.interface';

@Injectable()
export class CenterInterceptor implements HttpInterceptor {
    constructor(private storage: LocalStorageService) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const center = this.storage.get<Center>(StorageKey.CENTER);
        if (center) {
            request = request.clone({
                setHeaders: {
                    center: center.id
                }
            });
        }
        return next.handle(request);
    }
}
