import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { downloadEquipmentInformationPdfFromToolbar, downloadInformationPdfFromToolbar, exitEquipmentInformationSelectedScreen, exitInformationSelectedScreen } from 'src/app/store/root.actions';
import { EQUIPMENT_ACTIONS_MODE, fromRoot, INFORMATION_ACTIONS_MODE } from 'src/app/store/root.selectors';

@Component({
  selector: 'app-toolbar-actions',
  templateUrl: './toolbar-actions.component.html',
  styleUrls: ['./toolbar-actions.component.scss']
})
export class ToolbarActionsComponent {

  EQUIPMENT_ACTIONS_MODE = EQUIPMENT_ACTIONS_MODE;
  INFORMATION_ACTIONS_MODE = INFORMATION_ACTIONS_MODE;

  mode$ = this.store.select(fromRoot.selectToolbarActionsMode);


  constructor(private store: Store) {
  }



  exitInformationSelectedScreen() {
    this.store.dispatch(exitInformationSelectedScreen());
  }

  downloadInformationPdf() {
    this.store.dispatch(downloadInformationPdfFromToolbar());
  }


  exitEquipmentInformationSelectedScreen() {
    this.store.dispatch(exitEquipmentInformationSelectedScreen());
  }

  downloadEquipmentInformationPdf() {
    this.store.dispatch(downloadEquipmentInformationPdfFromToolbar());
  }
}
