import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector, select } from '@ngrx/store';
import { rootFeature } from './root.reducers';
import { Route } from '../core/enums/routes.enum';
import { UserInfo } from '../core/interfaces/user-info.interface';


const {
    selectEquipments,
    selectToolbarText,
    selectLoggedIn,
    selectUserInfo,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectInfomationList,
    selectLoadingInformation,
    selectEquipmentInfomationList,
    selectLoadingEquipmentInformation
} = rootFeature;
const { selectUrl, selectQueryParams } = getRouterSelectors();
const selectedEntity = createSelector(selectQueryParams, (params): string => {
    return params ? params['selected'] : undefined;
});

const selectPartId = createSelector(selectQueryParams, (params): string | undefined => {
    return params ? params['part'] : undefined;
});


const selectCurrentFeature = createSelector(selectUrl, (url) => {
    if (!url) return Route.home;
    if (url.includes('app/equipments')) return Route.equipments;
    if (url.includes('app/quotes')) return Route.quotes;
    if (url.includes('app/cart')) return Route.cart;
    if (url.includes('app/information')) return Route.information;
    return Route.home;
});


export const SELECTED_PATH_PARAM = 'filePath'
const selectSelectedFilePath = createSelector(selectQueryParams, (params): string => {
    return params ? params[SELECTED_PATH_PARAM] : undefined;
});

export const INFORMATION_ACTIONS_MODE = "information-actions";
export const EQUIPMENT_ACTIONS_MODE = "equipment-actions";
const selectToolbarActionsMode = createSelector(selectUrl, selectSelectedFilePath, (url, selectedFile) => {
    if (!selectedFile) {
        return 'default';
    }
    return url.includes('app/equipments/information') ? EQUIPMENT_ACTIONS_MODE : INFORMATION_ACTIONS_MODE;
})

export const SELECTED_EQUIPMENT_HEADER = "SELECTED_EQUIPMENT_HEADER";
export const DEFAULT = "DEFAULT";

const selectSideMenuHeaderMode = createSelector(selectUrl, selectedEntity, (url, selectedEntity) => {

    if (url.includes('app/equipments') && selectedEntity) {
        return SELECTED_EQUIPMENT_HEADER;
    }
    return DEFAULT;

});


const selectUserName = createSelector(selectUserInfo, (userInfo) => {
    if (!userInfo) {
        return '';
    }
    return `${userInfo.firstName ?? userInfo.email} ${userInfo.lastName ?? ''}`
})


export const fromRoot = {
    selectUrl,
    selectToolbarText,
    selectedEntity,
    selectEquipments,
    selectLoggedIn,
    selectUserName,
    selectPartId,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectCurrentFeature,
    selectInfomationList,
    selectLoadingInformation,
    selectToolbarActionsMode,
    selectEquipmentInfomationList,
    selectLoadingEquipmentInformation,
    selectSelectedFilePath,
    selectSideMenuHeaderMode,
    selectUserInfo
};

export {
    selectUrl,
    selectToolbarText,
    selectedEntity,
    selectEquipments,
    selectLoggedIn,
    selectUserName,
    selectPartId,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectCurrentFeature,
    selectInfomationList,
    selectToolbarActionsMode,
    selectEquipmentInfomationList,
    selectLoadingEquipmentInformation,
    selectSelectedFilePath,
    selectSideMenuHeaderMode,
    selectUserInfo
};
