<mat-sidenav-container
    (backdropClick)="isMobile ? toggleIsMenuOpened(false) : toggleExpanded(false)"
    [autosize]="true"
    class="container"
>
    <mat-sidenav
        [class.expanded]="isTablet && expanded"
        [class.shrunk]="isTablet && !expanded"
        [disableClose]="isTablet || isComputer"
        [mode]="isMobile || (isTablet && expanded) ? 'over' : 'side'"
        [opened]="isMobile ? isMenuOpen : true"
        class="sidenav-container"
    >
        <div class="sidenav">
            <div id="header">
                <a routerLink="/">
                    <img
                        alt="machinex-logo"
                        draggable="false"
                        id="header-logo"
                        src="assets/machinex-side-menu-logo.svg"
                        style="height: 48px"
                    />
                </a>
                <button
                    (click)="toggleExpanded(true)"
                    *ngIf="isTablet && !expanded"
                    id="header-menu-button"
                    mat-icon-button
                >
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
            <div class="sidenav-content">
                <app-side-menu-content
                    [current]="currentRoute$ | async"
                    [collapsedMode]="isTablet && !expanded"
                    (navigate)="onNavigation()"
                ></app-side-menu-content>

                <div class="bottom-tabs-container" *ngIf="(currentRoute$ |async) === 'home'" >
                    <app-center-manager></app-center-manager>
                    <app-user-tab></app-user-tab>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-toolbar
            [opened]="isMenuOpen"
            (toggleOpened)="toggleIsMenuOpened($event)"
        ></app-toolbar>
        <div id="container-content" (scroll)="onScroll($event)">
            <ng-content select="[content]"></ng-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
