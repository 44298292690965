import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter } from 'rxjs';
import { Route } from 'src/app/core/enums/routes.enum';
import * as EquipmentActions from 'src/app/modules/equipments/store/equipments.actions';
import { fromEquipments } from 'src/app/modules/equipments/store/equipments.selectors';
import { Equipment } from 'src/app/shared/interfaces/equipment.interface';
import { Information } from 'src/app/shared/interfaces/information.interface';
import { RecentEquipmentsService } from 'src/app/shared/services/recent-equipments.service';
import * as RootActions from 'src/app/store/root.actions';
import { SELECTED_PATH_PARAM, fromRoot, selectEquipments, selectedEntity } from 'src/app/store/root.selectors';

@Component({
  selector: 'app-equipments-list',
  templateUrl: './equipments-list.component.html',
  styleUrls: ['./equipments-list.component.scss'],
  animations: [
    trigger('delayedFadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.3s 400ms', style({ opacity: 1 }))
      ]),
    ]),
  ],
})
export class EquipmentsListComponent implements OnInit, OnDestroy {

  equipments$: Observable<Equipment[]>;
  information$: Observable<Information[] | null>;
  selectedTabId$: Observable<string>;
  selectedFile$: Observable<string>;

  subscriptions: Subscription[] = [];


  VIEWER_ROUTE = {
    title: 'Viewer',
    id: Route.viewer,
    icon: 'deployed_code',
    routerLink: "equipments/viewer",
    isSvgIcon: true,
    isActive: false,
    onClick: () => {
      this.router.navigate(['equipments', 'viewer'], {
        relativeTo: this.route,
        queryParams: { selected: this.route.snapshot.queryParams['selected'] }
      });
    }
  }

  INFORMATION = {
    title: 'Information',
    id: Route.equipmentInformation,
    routerLink: "equipments/information",
    icon: 'description',
    isSvgIcon: false,
    isActive: false,
    onClick: () => {
      this.router.navigate(['equipments', 'information'], {
        relativeTo: this.route,
        queryParams: { selected: this.route.snapshot.queryParams['selected'] } // This will preserve selected while replacing the current file path
      });
    }
  }


  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private recentEquipmentsService: RecentEquipmentsService
  ) {
    this.equipments$ = store.select(selectEquipments);
    this.information$ = store.select(fromRoot.selectEquipmentInfomationList);
    this.selectedTabId$ = store.select(selectedEntity);
    this.selectedFile$ = store.select(fromRoot.selectSelectedFilePath);
  }

  ngOnInit(): void {
    this.store.dispatch(RootActions.filterEquipmentById({}));

    this.subscriptions.push(
      this.store.select(selectedEntity).subscribe((id) => {
        if (id) {
          this.store.dispatch(EquipmentActions.getEquipmentById({ id }));
          this.store.dispatch(RootActions.getEquipmentInformation({ equipmentId: id }));

        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(fromEquipments.selectEquipment).pipe(
          // this will make sure that even if the selected equipment is in store,
          // we only show the toolbar text when there is a selected query param
          concatLatestFrom(() => this.store.select(selectedEntity)))
        .subscribe(([equipment, entity]) => {
          if (equipment && entity) {
            this.store.dispatch(
              RootActions.setToolbarText({
                text: `${equipment!.name} (${equipment!.number})`
              })
            );
          }
        })

    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  trackByFn(index: number, item: Equipment): string {
    return item.id;
  }

  searchFunction(query: string) {
    this.store.dispatch(RootActions.filterEquipmentById({ query }));
  }

  handleEquipmentSelection(equipment: Equipment) {
    this.recentEquipmentsService.addToRecentEquipments(equipment);
    this.router.navigate([`app/equipments`], {
      queryParams: {
        selected: equipment.id
      }
    });
  }



  informationTrackByFn(index: number, item: Information): string {
    return item.path;
  }

  handleInformationSelection(information: Information) {
    this.router.navigate(['app/equipments/information'], {
      queryParams: {
        [SELECTED_PATH_PARAM]: information.path
      },
      queryParamsHandling: 'merge'
    });
  }



}
