<div class="subTab-container">
    <!-- appScrollShadows
    appAutoScrollToSelected
    [selectedId]="selectedTabId$ | async"
    [items$]="equipments$" -->
    <ng-container *ngIf="equipments$ | async as equipments">
        <app-text-item
            *ngFor="let equipment of equipments; trackBy: trackByFn"
            [mainText1]="equipment.number"
            [mainText2]="equipment.name"
            [description]="equipment.description"
            [isSelected]="equipment.id === (selectedTabId$ | async)"
            (subTabSelected)="handleEquipmentSelection(equipment)"
        ></app-text-item>

        <app-text-item
            *ngIf="!equipments.length"
            @delayedFadeIn
            mainText1="No equipment found"
            description="Refine your search or choose another plant."
            [isSelected]="false"
        ></app-text-item>
    </ng-container>
</div>
