export interface Information {
    path: string;
    name: string;
    date: Date;
    language: InformationLanguage;
}

export enum InformationLanguage {
    multilingual = 0,
    french = 1,
    english = 2
}

export interface InformationDetail extends Information {
    blob: Blob;
}