<div class="side-menu-tab-container">
    <a
        class="justify-start"
        [class.disable-link]="disableLink"
        [routerLinkActive]="menuTab.routerLink ? 'active-link' : ''"
        [routerLink]="menuTab.routerLink"
        (click)="handleOnClick()"
    >
        <mat-icon class="main-icon" *ngIf="menuTab.icon" [svgIcon]="menuTab.isSvgIcon ? menuTab.icon : ''">
            {{ !menuTab.isSvgIcon ? menuTab.icon : '' }}
        </mat-icon>
        <div class="label">
            {{ menuTab.title | translate }}
            {{ count ? '(' + count + ')' : '' }}
        </div>
        <mat-icon class="collapseIcon" *ngIf="showCollapseIcon">
            {{ collapseIcon }}
        </mat-icon>
    </a>
</div>
