import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { fromInformation } from 'src/app/modules/information/store/information.selectors';
import { Information } from 'src/app/shared/interfaces/information.interface';
import { getAllInformation } from 'src/app/store/root.actions';
import { SELECTED_PATH_PARAM, selectedEntity, selectInfomationList, selectSelectedFilePath } from 'src/app/store/root.selectors';

@Component({
  selector: 'app-information-list',
  templateUrl: './information-list.component.html',
  styleUrls: ['./information-list.component.scss']
})
export class InformationListComponent {
  information$: Observable<Information[] | null>;
  selectedTabId$: Observable<string>;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.information$ = store.select(selectInfomationList);
    this.selectedTabId$ = store.select(selectSelectedFilePath);
  }


  ngOnInit(): void {
    this.store.dispatch(getAllInformation());
  }

  trackByFn(index: number, item: Information): string {
    return item.path;
  }

  handleSelection(equipment: Information) {
    this.router.navigate([`app/information`], {
      queryParams: {
        [SELECTED_PATH_PARAM]: equipment.path
      }
    });
  }

}
