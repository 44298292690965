<div id="dialog">
    <div>
        <h1 mat-dialog-title>Attention!</h1>
        <div mat-dialog-content>
            <p>You are about to <b>delete this item</b>.</p>
        </div>
        <h3>Do you want to proceed?</h3>

        <div class="buttons-container" mat-dialog-actions>
            <app-custom-button
                fontColorMode="light"
                color="primary"
                text="Yes"
                [styles]="{ 'background-color': 'var(--primary-color)' }"
                (buttonClick)="onAccept()"
            ></app-custom-button>
            <app-custom-button
                text="No"
                color="primary"
                type="stroked"
                [styles]="{ border: '1px solid #00568C' }"
                (buttonClick)="onDiscard()"
            ></app-custom-button>
        </div>
    </div>
</div>
