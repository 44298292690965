<div
    class="subTab-container"
    >
    <!-- appScrollShadows
    appAutoScrollToSelected
    [selectedId]="selectedTabId$ | async"
    [items$]="quotes$" -->
    <app-text-item
        *ngFor="let info of information$ | async; trackBy: trackByFn"
        [mainText2]="info.name"
        [isSelected]="info.path === (selectedTabId$ | async)"
        (subTabSelected)="handleSelection(info)"
    ></app-text-item>
</div>
