<div class="center-tab-container">
    <ng-container *ngIf="showOptions">
        <div
            *ngIf="currentLocale"
            class="opt-container option active"
            [title]="currentLocale | translate"
            (click)="showHide()"
        >
            <mat-icon>g_translate</mat-icon>
            <div class="text">{{ currentLocale | translate }}</div>
        </div>
        <div class="divider"></div>
        <div class="center-container scroll-grid">
            <span class="header">{{ 'Change Language' | translate }}</span>
            <div class="body">
                <ng-container *ngFor="let item of localesList">
                    <div
                        class="opt-container option"
                        *ngIf="item.label !== currentLocale"
                        [title]="item.label | translate"
                        (click)="switchLocale(item.code)"
                    >
                        <mat-icon></mat-icon>
                        <div class="text">{{ item.label | translate }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div *ngIf="currentLocale; else skeleton">
        <div
            [title]="currentLocale"
            class="option opt-container"
            *ngIf="!showOptions"
            (click)="showHide()"
        >
            <mat-icon>g_translate</mat-icon>
            <div class="text">{{ currentLocale | translate }}</div>
        </div>
    </div>

    <ng-template #skeleton>
        <div class="option skeleton">
            <ngx-skeleton-loader count="1" appearance="circle" animation="pulse">
            </ngx-skeleton-loader>
            <div>
                <ngx-skeleton-loader count="1" appearance="line" animation="pulse">
                </ngx-skeleton-loader>
            </div>
        </div>
    </ng-template>
</div>
