import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { downloadInformationPdfFromToolbar, exitInformationSelectedScreen } from 'src/app/store/root.actions';
import { fromRoot } from 'src/app/store/root.selectors';

@Component({
  selector: 'app-toolbar-actions',
  templateUrl: './toolbar-actions.component.html',
  styleUrls: ['./toolbar-actions.component.scss']
})
export class ToolbarActionsComponent {
  INFORMATION_ACTIONS_MODE = "information-actions";

  mode$ = this.store.select(fromRoot.selectToolbarActionsMode);


  constructor(private store: Store) {
  }



  exitInformationSelectedScreen() {
    this.store.dispatch(exitInformationSelectedScreen());
  }

  downloadInformationPdf() {
    this.store.dispatch(downloadInformationPdfFromToolbar());
  }
}
