import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { QuoteSummary } from '../interfaces/quote-summary.interface';
import { ResponseObject } from '../interfaces/response-object.interface';
import { Observable } from 'rxjs';
import { Quote } from '../interfaces/quote.interface';

@Injectable({
    providedIn: 'root'
})
export class QuoteService {
    constructor(private apiService: ApiService) {}

    getQuoteList(): Observable<ResponseObject<QuoteSummary[]>> {
        return this.apiService.get('cart/quotes');
    }

    getQuote(id: string): Observable<ResponseObject<Quote>> {
        return this.apiService.get('cart/quotes/' + id);
    }

    checkout(): Observable<ResponseObject<boolean>> {
        return this.apiService.post('cart/quotes/checkout');
    }
}
