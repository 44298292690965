import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const extension = value.split('.').pop()?.toLowerCase();
    if (extension == null) {
      return 'unknown';
    }
    return this.iconMap[extension] || 'unknown';
  }
  private readonly iconMap: { [key: string]: string } = {
    'pdf': 'file_pdf',
    'doc': 'file_doc',
    'docx': 'file_doc',
    'xls': 'file_excel',
    'xlsx': 'file_excel'
  }
};


