import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { rootFeature } from './root.reducers';
import { Route } from '../core/enums/routes.enum';


const {
    selectEquipments,
    selectToolbarText,
    selectLoggedIn,
    selectUserName,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectInfomationList,
    selectLoadingInformation
} = rootFeature;
const { selectUrl, selectQueryParams } = getRouterSelectors();
const selectedEntity = createSelector(selectQueryParams, (params) => {
    return params ? params['selected'] : undefined;
});

const selectPartId = createSelector(selectQueryParams, (params): string | undefined => {
    return params ? params['part'] : undefined;
});


const selectCurrentFeature = createSelector(selectUrl, (url) => {
    if (!url) return Route.home;
    if (url.includes('app/equipments')) return Route.equipments;
    if (url.includes('app/quotes')) return Route.quotes;
    if (url.includes('app/cart')) return Route.cart;
    if (url.includes('app/information')) return Route.information;

    return Route.home;
});

const selectToolbarActionsMode = createSelector(selectCurrentFeature, selectedEntity, (feature, selectedEntity) => {
    return feature === Route.information && selectedEntity ? 'information-actions' : 'default';
})


export const fromRoot = {
    selectUrl,
    selectToolbarText,
    selectedEntity,
    selectEquipments,
    selectLoggedIn,
    selectUserName,
    selectPartId,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectCurrentFeature,
    selectInfomationList,
    selectLoadingInformation,
    selectToolbarActionsMode
};

export {
    selectUrl,
    selectToolbarText,
    selectedEntity,
    selectEquipments,
    selectLoggedIn,
    selectUserName,
    selectPartId,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectCurrentFeature,
    selectInfomationList,
    selectToolbarActionsMode
};
