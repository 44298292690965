<div class="quantity-selector">
    <app-quantity-selector [(quantity)]="value"></app-quantity-selector>
    <app-custom-button
        [icon]="icon"
        [text]="label"
        color="primary"
        [styles]="{ 'background-color': 'var(--primary-color)' }"
        [borderRadius]="25"
        (buttonClick)="sendQuantity()"
        fontColorMode="light"
    ></app-custom-button>
</div>
