export const environment = {
    production: false,
    config: {
        backendScheme: 'https://',
        backendUrl: 'machback.dev.sav.machinex.ingeniu.ca',
        backendUrlVersion: 'api',
        keycloak: {
            realm: 'MachinexWebApp',
            url: 'https://auth.dev.sav.machinex.ingeniu.ca',
            clientId: 'WebApp'
        },
        excludeAuth: ['https://machinex-repository-dev.s3.us-east-2.amazonaws.com'],
        mixpanelToken: 'df4d6e5e1271a653e70d366c94e0a157',
        saveInsights: true
    }
};
