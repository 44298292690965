<div class="carts-side-menu">
    <app-side-menu-tab
        class="cart-menu-tabs"
        [menuTab]="CART_ROUTE"
        [count]="(cartCount$ | async) ?? undefined"
    ></app-side-menu-tab>
    <app-side-menu-tab
        class="cart-menu-tabs"
        [menuTab]="HISTORY_ROUTE"
        [showCollapseIcon]="true"
        [collapseIcon]="showQuotes ? 'arrow_drop_up' : 'arrow_drop_down' "
        (click)="onHistoryClick()"
    ></app-side-menu-tab>
    <app-quotes-list [class.closed]="!showQuotes" ></app-quotes-list>
</div>
