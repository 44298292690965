import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Information } from '../../interfaces/information.interface';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.scss']
})
export class FilesTableComponent implements OnChanges {

  isPdfDict: { [key: string]: boolean } = {};

  @Input() dataSource: Information[] = [];
  @Input() displayedColumns: string[] = [];
  @Output() go = new EventEmitter<Information>();
  @Output() download = new EventEmitter<Information>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.updateDataSourceFields();
    }
  }
  onGo(item: Information) {
    this.go.emit(item);
  }

  onDownload(item: Information) {
    this.download.emit(item);
  }
  updateDataSourceFields() {
    this.isPdfDict = this.dataSource.reduce((acc, item) => {
      acc[item.name] = item.name.endsWith('.pdf');
      return acc;
    }, {} as { [key: string]: boolean });
  }

  showClick(item: any): boolean {
    return !!this.isPdfDict[item.name];
  }

}

