<div >
    <app-side-menu-tab
        class="cart-menu-tabs"
        [menuTab]="CART_ROUTE"
        [count]="(cartCount$ | async) ?? undefined"
    ></app-side-menu-tab>
    <app-side-menu-tab
        class="cart-menu-tabs"
        [menuTab]="HISTORY_ROUTE"
    ></app-side-menu-tab>
</div>

<app-quotes-list></app-quotes-list>