<div
    id="txt-item"
    *ngIf="mainText1|| mainText2 || description"
    class="text-item-container" 
    [class.selected-tab]="isSelected"
    [class.text-with-ellipsis]="textWithEllipsis"
    (click)="onClick()"
    >
        <div class="main-text">
            <span *ngIf="mainText1" [title]="mainText1" class="text1">{{mainText1}}</span>
            <span [title]="mainText2" class="text2">{{mainText2}}</span>
        </div>
        <div class="secondary-text" [ngStyle]="{'color': isSelected ? 'var(--primary-color)' : ''}">{{description}}</div>
</div>