import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, map } from 'rxjs';
import { selectQuoteList, selectedEntity } from 'src/app/store/root.selectors';
import { transformQuoteIntoSubTab } from '../../helpers/transform.functions';
import { SubMenuTab } from 'src/app/modules/interfaces/menu-tab';
import { getQuoteList } from 'src/app/store/root.actions';

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.scss']
})
export class QuotesListComponent implements OnInit {

  onDestroy$ = new Subject<boolean>();
  quotes$: Observable<SubMenuTab[]>;
  selectedTabId$: Observable<string>;

  constructor(
    private store: Store,
    private router: Router,
  ) {

    this.quotes$ = this.store.select(selectQuoteList).pipe(map(result => result.map(transformQuoteIntoSubTab)));
    this.selectedTabId$ = store.select(selectedEntity);
  }

  ngOnInit(): void {
    this.store.dispatch(getQuoteList());
  }

  handleQuoteSelection(quote: SubMenuTab) {
    this.router.navigate([`app/carts/history`], {
      queryParams: {
        selected: quote.id
      }
    });
  }

  trackByFn(index: number, item: SubMenuTab): string {
    return item.id;
  }
}
