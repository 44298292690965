import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceType } from '../enums/device-type.enum';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    TABLET_MEDIA_QUERY = '(max-width: 768px)';
    MOBILE_MEDIA_QUERY = '(max-width: 425px)';
    constructor(private breakpointObserver: BreakpointObserver) {}

    currentDevice(): Observable<DeviceType> {
        return this.breakpointObserver
            .observe([this.MOBILE_MEDIA_QUERY, this.TABLET_MEDIA_QUERY])
            .pipe(
                map((state: BreakpointState) => {
                    if (state.breakpoints[this.MOBILE_MEDIA_QUERY]) {
                        return DeviceType.MOBILE;
                    }
                    if (state.breakpoints[this.TABLET_MEDIA_QUERY]) {
                        return DeviceType.TABLET;
                    }
                    return DeviceType.COMPUTER;
                })
            );
    }

    isMobile(): boolean {
        return this.breakpointObserver.isMatched(this.MOBILE_MEDIA_QUERY);
    }

    isTablet(): boolean {
        return (
            !this.isMobile() && this.breakpointObserver.isMatched(this.TABLET_MEDIA_QUERY)
        );
    }
}
