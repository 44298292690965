<div class="equipment-info-container" *ngIf="selectedTabId$ | async as selected; else list">
    <app-side-menu-tab class="smaller-icon" [menuTab]="VIEWER_ROUTE"></app-side-menu-tab>
    <app-side-menu-tab class="smaller-icon" [menuTab]="INFORMATION"></app-side-menu-tab>
    <div class="subTab-container">
        <!-- appScrollShadows
    appAutoScrollToSelected
    [selectedId]="selectedTabId$ | async"
    [items$]="quotes$" -->
        <app-text-item
            *ngFor="let info of information$ | async; trackBy: informationTrackByFn"
            [mainText2]="info.name"
            [isSelected]="info.path === (selectedFile$ | async)"
            [textWithEllipsis]="true"
            (subTabSelected)="handleInformationSelection(info)"
        ></app-text-item>
    </div>
</div>
<ng-template #list>
    <app-search-input (search)="searchFunction($event)"></app-search-input>
    <div class="subTab-container equipment">
        <!-- appScrollShadows
        appAutoScrollToSelected
        [selectedId]="selectedTabId$ | async"
        [items$]="equipments$" -->
        <ng-container *ngIf="equipments$ | async as equipments">
            <app-text-item
                *ngFor="let equipment of equipments; trackBy: trackByFn"
                [mainText1]="equipment.number"
                [mainText2]="equipment.name"
                [description]="equipment.description"
                (subTabSelected)="handleEquipmentSelection(equipment)"
            ></app-text-item>

            <app-text-item
                *ngIf="!equipments.length"
                @delayedFadeIn
                mainText1="No equipment found"
                description="Refine your search or choose another plant."
                [isSelected]="false"
            ></app-text-item>
        </ng-container>
    </div>
</ng-template>
