import { Injectable } from '@angular/core';
import { NotificationsService } from '../core/services/notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RootActions from './root.actions';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { EquipmentService } from '../shared/services/equipment.service';
import { Equipment } from '../shared/interfaces/equipment.interface';
import { ResponseObject } from '../shared/interfaces/response-object.interface';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { notificationError } from './root.actions';
import { KeycloakProfile } from 'keycloak-js';
import { QuoteSummary } from '../shared/interfaces/quote-summary.interface';
import { QuoteService } from '../shared/services/quote.service';
import { CenterService } from '../shared/services/center.service';
import { Center } from '../shared/interfaces/center.interface';
import { LocalStorageService } from '../core/services/local-storage.service';
import { StorageKey } from '../core/enums/storage-key.enum';
import { RootState } from './root.reducers';
import { Store } from '@ngrx/store';
import { CartService } from '../shared/services/cart.service';
import { InformationService } from '../shared/services/information.service';
import { Information } from '../shared/interfaces/information.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RootEffects {
    constructor(
        private actions$: Actions,
        private notificationsService: NotificationsService,
        private equipmentsService: EquipmentService,
        private informationService: InformationService,
        private quoteService: QuoteService,
        private centerService: CenterService,
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private cartService: CartService,
        private notifications: NotificationsService,
        private translateService: TranslateService,
        private store: Store<RootState>
    ) {}

    notificationInfo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(RootActions.notificationInfo),
                tap(({ message }) => this.notificationsService.info(message))
            );
        },
        { dispatch: false }
    );

    notificationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(RootActions.notificationSuccess),
                tap(({ message }) => this.notificationsService.success(message))
            );
        },
        { dispatch: false }
    );

    notificationWarning$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(RootActions.notificationWarning),
                tap(({ message }) => this.notificationsService.warning(message))
            );
        },
        { dispatch: false }
    );

    notificationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(RootActions.notificationError),
                tap(({ message }) => this.notificationsService.error(message))
            );
        },
        { dispatch: false }
    );

    getAllEquipments$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RootActions.filterEquipmentById),
            mergeMap(({ query }) => {
                return this.equipmentsService.getEquipments(query).pipe(
                    map((response: ResponseObject<Equipment[]>) => {
                        return RootActions.getAllEquipmentsSuccess({
                            equipments: response.result
                        });
                    }),
                    catchError((error) => of(RootActions.getAllEquipmentsFailure(error)))
                );
            })
        );
    });

    getAllInformation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RootActions.getAllInformation),
            mergeMap(() => {
                return this.informationService.getAll().pipe(
                    map((response: ResponseObject<Information[]>) => {
                        return RootActions.getAllInformationSuccess({
                            information: response.result
                        });
                    }),
                    catchError((error) => of(RootActions.getAllInformationFailure(error)))
                );
            })
        );
    });

    loginKeycloakRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RootActions.loginKeycloakRequest),
            mergeMap(() => this.authService.login()),
            map((success: boolean) => {
                return success
                    ? RootActions.loginSuccess()
                    : RootActions.loginFailure({
                          message: 'Contact the admin of the Identity provider'
                      });
            }),
            catchError(() =>
                of(RootActions.loginFailure({ message: 'Connection problems' }))
            )
        );
    });

    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RootActions.loginSuccess),
                map(() => this.router.navigateByUrl('/app/home'))
            ),
        { dispatch: false }
    );

    loginFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.loginFailure),
            map(({ message }) => notificationError({ message }))
        )
    );

    logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RootActions.logoutSuccess),
                map(() => {
                    this.localStorageService.clear(StorageKey.CENTER);
                    return this.router.navigateByUrl('/')
                })
            ),
        { dispatch: false }
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.logout),
            mergeMap(() => this.authService.logout()),
            map((success: boolean) => {
                return success
                    ? RootActions.logoutSuccess()
                    : RootActions.logoutFailure({ message: 'Logout failed' });
            }),
            catchError(() => of(RootActions.logoutFailure({ message: 'Logout failed' })))
        )
    );

    getUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.getUserInfo),
            mergeMap(() => this.authService.getUserInfo()),
            map((userInfo: KeycloakProfile) =>
                RootActions.getUserInfoSuccess({
                    userName: `${userInfo.firstName?? userInfo.email} ${userInfo.lastName?? ''}`
                })
            ),
            catchError(() =>
                of(
                    RootActions.getUserInfoFailure({
                        message: 'Error getting the user info'
                    })
                )
            )
        )
    );

    getQuoteList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.getQuoteList),
            mergeMap(() => {
                return this.quoteService.getQuoteList().pipe(
                    map((response: ResponseObject<QuoteSummary[]>) => {
                        return RootActions.getQuoteListSuccess({
                            quoteList: response.result
                        });
                    }),
                    catchError((error) => {
                        return of(RootActions.getQuoteListFailure(error));
                    })
                );
            })
        )
    );

    getCenters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.getCenters),
            mergeMap(() => {
                return this.centerService.getCenters().pipe(
                    map((response: ResponseObject<Center[]>) => {
                        return RootActions.getCentersSuccess({
                            centers: response.result
                        });
                    }),
                    catchError((error) => {
                        return of(RootActions.getCentersFailure(error));
                    })
                );
            })
        )
    );

    getCentersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.getCentersSuccess),
            map(({ centers }) => {
                const center = this.localStorageService.get<Center>(StorageKey.CENTER);

                if (center && centers.find((c) => c.id === center.id))
                    return RootActions.changeCenterSuccess({ center });

                if (centers.length > 0) {
                    this.localStorageService.set(StorageKey.CENTER, centers[0]);
                    return RootActions.changeCenterSuccess({ center: centers[0] });
                }

                return RootActions.changeCenterSuccess({
                    center: {
                        description: '',
                        id: '',
                        name: ''
                    }
                });
            })
        )
    );

    
    setCenterFromLocalStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.setCenterFromLocalStorage),
            map(() => {
                const center = this.localStorageService.get<Center>(StorageKey.CENTER);
                if (!center) return RootActions.getCenters();
                return RootActions.setCenterFromLocalStorageSucess({ center });
            })
        )
    );

    setLanguageFromLocalStorage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.setLanguageFromLocalStorage),
            map(() => {
                const language = localStorage.getItem(StorageKey.LANGUAGE);
                if (language) {
                    this.translateService.use(language);
                }
            })
        ), { dispatch: false }
    );

    changeCenter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.changeCenter),
            map(({ center }) => {
                this.localStorageService.set(StorageKey.CENTER, center);
                return RootActions.changeCenterSuccess({ center });
            })
        )
    );

    // changeCenterSuccess
    changeCenterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.changeCenterSuccess),
            map(() => {
                return RootActions.filterEquipmentById({});
            })
        )
    );

    getCartCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RootActions.getCartCount),
            mergeMap(() => {
                return this.cartService.getCartCount().pipe(
                    map((response: ResponseObject<number>) => {
                        return RootActions.getCartCountSuccess({
                            count: response.result
                        });
                    }),
                    catchError((error) => {
                        this.notifications.error('ERROR');
                        return of(RootActions.getCartCountFailure(error));
                    })
                );
            })
        );
    });
}
