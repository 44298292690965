import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { Route } from '../core/enums/routes.enum';
import { getLanguageEnum } from '../core/helpers/localization';
import { Information, InformationLanguage } from '../shared/interfaces/information.interface';
import { rootFeature } from './root.reducers';


const {
    selectEquipments,
    selectToolbarText,
    selectLoggedIn,
    selectUserInfo,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectInfomationList,
    selectLoadingInformation,
    selectEquipmentInfomationList,
    selectLoadingEquipmentInformation,
    selectLoadingEquipments,
    selectLanguageCode
} = rootFeature;
const { selectUrl, selectQueryParams } = getRouterSelectors();
const selectedEntity = createSelector(selectQueryParams, (params): string => {
    return params ? params['selected'] : undefined;
});

const selectPartId = createSelector(selectQueryParams, (params): string | undefined => {
    return params ? params['part'] : undefined;
});


const selectCurrentFeature = createSelector(selectUrl, (url) => {
    if (!url) return Route.home;
    if (url.includes('app/equipments')) return Route.equipments;
    if (url.includes('app/quotes')) return Route.quotes;
    if (url.includes('app/cart')) return Route.cart;
    if (url.includes('app/information')) return Route.information;
    return Route.home;
});


export const SELECTED_PATH_PARAM = 'filePath'
const selectSelectedFilePath = createSelector(selectQueryParams, (params): string => {
    return params ? params[SELECTED_PATH_PARAM] : undefined;
});

export const INFORMATION_ACTIONS_MODE = "information-actions";
export const EQUIPMENT_ACTIONS_MODE = "equipment-actions";
const selectToolbarActionsMode = createSelector(selectUrl, selectSelectedFilePath, (url, selectedFile) => {
    if (!selectedFile) {
        return 'default';
    }
    return url.includes('app/equipments/information') ? EQUIPMENT_ACTIONS_MODE : INFORMATION_ACTIONS_MODE;
})

export const SELECTED_EQUIPMENT_HEADER = "SELECTED_EQUIPMENT_HEADER";
export const DEFAULT = "DEFAULT";

const selectSideMenuHeaderMode = createSelector(selectUrl, selectedEntity, (url, selectedEntity) => {

    if (url.includes('app/equipments') && selectedEntity) {
        return SELECTED_EQUIPMENT_HEADER;
    }
    return DEFAULT;

});


const selectUserName = createSelector(selectUserInfo, (userInfo) => {
    if (!userInfo) {
        return '';
    }
    return `${userInfo.firstName ?? userInfo.email} ${userInfo.lastName ?? ''}`
})


function filterByLanguage(code: string, items: Information[]) {
    const currentLang = getLanguageEnum(code);
        return items.filter(item => {
            return item.language == currentLang || item.language == InformationLanguage.multilingual
        });
}

export const selectInformationListForLanguage = createSelector(
    selectInfomationList,
    selectLanguageCode,
    (items: Information[], code: string) => {
        return filterByLanguage(code, items);
    }
);


export const selectEquipmentInfomationListForLanguage = createSelector(
    selectEquipmentInfomationList,
    selectLanguageCode,
    (items: Information[], code: string) => {
        return filterByLanguage(code, items);
    }
);



export const fromRoot = {
    selectUrl,
    selectToolbarText,
    selectedEntity,
    selectEquipments,
    selectLoggedIn,
    selectUserName,
    selectPartId,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectCurrentFeature,
    selectInfomationList,
    selectInformationListForLanguage,
    selectLoadingInformation,
    selectToolbarActionsMode,
    selectEquipmentInfomationList,
    selectEquipmentInfomationListForLanguage,
    selectLoadingEquipmentInformation,
    selectSelectedFilePath,
    selectSideMenuHeaderMode,
    selectUserInfo,
    selectLoadingEquipments
};

export {
    selectCartCount, selectCenters,
    selectCurrentCenter, selectCurrentFeature, selectedEntity,
    selectEquipments, selectLoadingEquipmentInformation, selectLoadingEquipments, selectLoggedIn, selectPartId,
    selectQuoteList, selectSelectedFilePath,
    selectSideMenuHeaderMode, selectToolbarActionsMode, selectToolbarText, selectUrl, selectUserInfo, selectUserName
};

