import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Equipment } from 'src/app/shared/interfaces/equipment.interface';
import { selectEquipments, selectedEntity } from 'src/app/store/root.selectors';
import { RecentEquipmentsService } from 'src/app/shared/services/recent-equipments.service';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { filterEquipmentById } from 'src/app/store/root.actions';

@Component({
  selector: 'app-equipments-list',
  templateUrl: './equipments-list.component.html',
  styleUrls: ['./equipments-list.component.scss'],
  animations: [
    trigger('delayedFadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.3s 400ms', style({ opacity: 1 }))
      ]),
    ]),
  ],
})
export class EquipmentsListComponent implements OnInit {
  equipments$: Observable<Equipment[]>;
  selectedTabId$: Observable<string>;


  constructor(
    private store: Store,
    private router: Router,
    private recentEquipmentsService: RecentEquipmentsService
  ) {
    this.equipments$ = store.select(selectEquipments);
    this.selectedTabId$ = store.select(selectedEntity);
  }

  ngOnInit(): void {
    this.store.dispatch(filterEquipmentById({}));
  }

  trackByFn(index: number, item: Equipment): string {
    return item.id;
  }

  handleEquipmentSelection(equipment: Equipment) {
    this.recentEquipmentsService.addToRecentEquipments(equipment);
    this.router.navigate([`app/equipments`], {
      queryParams: {
        selected: equipment.id
      }
    });
  }

}
