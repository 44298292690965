import { Injectable } from '@angular/core';
import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    set<T>(key: StorageKey, data: T) {
        localStorage.setItem(key, JSON.stringify(data));
    }
    get<T>(key: StorageKey): T | null {
        const item = localStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : null;
    }
    clear(key: StorageKey): void {
        localStorage.removeItem(key);
    }
}
