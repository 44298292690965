import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { MenuTab } from '../../../interfaces/menu-tab';
import { Store } from '@ngrx/store';
import {
    selectCartCount,
    selectCenters,
    selectCurrentCenter,
} from 'src/app/store/root.selectors';
import * as RootActions from 'src/app/store/root.actions';
import { Center } from 'src/app/shared/interfaces/center.interface';
import { Route } from 'src/app/core/enums/routes.enum';

@Component({
    selector: 'app-side-menu-content',
    templateUrl: './side-menu-content.component.html',
    styleUrls: ['./side-menu-content.component.scss']
})
export class SideMenuContentComponent implements OnInit, OnDestroy {
    @Input() current: Route | null = null;
    @Input() collapsedMode = false;
    @Output() navigate = new EventEmitter();
    onDestroy$ = new Subject<boolean>();


    routes: { [key: string]: MenuTab };
    bottomRoutes: { [key: string]: MenuTab };
    routesIds: string[] = [];
    bottomRoutesIds: string[] = [];
    selectCenters$: Observable<Center[] | null>;
    haveCenters: boolean = true;

    constructor(
        private router: Router,
        private store: Store
    ) {
        this.selectCenters$ = this.store.select(selectCenters);

        this.routes = {
            [Route.home]: {
                title: 'Home',
                id: Route.home,
                routerLink: 'home',
                icon: 'home',
                isSvgIcon: true,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/home');
                }
            },
            [Route.equipments]: {
                title: 'Equipments',
                id: Route.equipments,
                routerLink: 'equipments',
                icon: 'construction',
                isSvgIcon: true,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/equipments');
                }
            },
            [Route.information]: {
                title: 'Information',
                id: Route.information,
                routerLink: 'information',
                icon: 'description',
                isSvgIcon: false,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/information');
                }
            }
        };
        this.bottomRoutes = {
            [Route.cart]: {
                title: 'Carts',
                id: Route.cart,
                routerLink: 'carts',
                icon: 'shopping_cart',
                isSvgIcon: false,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/carts');
                }
            }
        };

        this.routesIds = Object.values(this.routes).map((route) => route.id);
        this.bottomRoutesIds = Object.values(this.bottomRoutes).map((route) => route.id);
    }


    ngOnInit(): void {
        this.selectCenters$
            .pipe(take(2))
            .subscribe((centers) => {
                if (centers === null) {
                    this.haveCenters = true;
                }
                else {
                    this.haveCenters = centers?.length > 0;
                }
            });
    }

    searchFunction(query: string) {
        this.store.dispatch(RootActions.filterEquipmentById({ query }));
    }

    onNavigation() {
        this.navigate.emit();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    navigateToRoot() {
        this.router.navigate(['/']);
    }

    get isRoot() {
        return this.current === Route.home;
    }

    get isEquipment() {
        return this.current === Route.equipments;
    }

    get isInformation() {
        return this.current === Route.information;
    }

    get isCart() {
        return this.current === Route.cart;
    }

    // YOU MUST ADD THIS MAPPING IN root.selectors.ts --> selectCurrentFeature
    get currentRoute(): MenuTab {
        switch (this.current) {
            case Route.cart:
                return this.bottomRoutes['carts'];
            case Route.equipments:
                return this.routes['equipments'];
            case Route.information:
                return this.routes['information'];
            default:
                return this.routes['home'];;
        }
    }

}
