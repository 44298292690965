import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectToolbarText } from 'src/app/store/root.selectors';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations: [
        trigger('toolBar', [
            state('show', style({ top: '0px' })),
            state('hide', style({ top: '-70px' })),
            transition('show => hide', [animate('0.3s')]),
            transition('hide => show', [animate('0.3s')])
        ])
    ]
})
export class ToolbarComponent implements OnDestroy {
    @Input() hideToolbar!: boolean;
    @Input() opened!: boolean;
    @Output() toggleOpened = new EventEmitter<boolean>();

    toolbarText$: Observable<string>;
    subscriptions: Subscription[] = [];

    constructor(private store: Store) {
        this.toolbarText$ = this.store.select(selectToolbarText);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
