import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CellClickEventResponse, ListItem } from '../../interfaces/list-view.interface';

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {
    @Input() title = '';
    @Input() listData: ListItem[] = [];
    @Output() clickOnCell: EventEmitter<CellClickEventResponse> =
        new EventEmitter<CellClickEventResponse>();

    handleClickOnCell(rowIndex: number, cellIndex: number) {
        this.clickOnCell.emit({
            rowIndex,
            cellIndex,
            item: this.listData[rowIndex],
            cell: this.listData[rowIndex].cells[cellIndex]
        });
    }
}
