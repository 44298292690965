import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { CartListComponent } from './components/cart-list/cart-list.component';
import { CenterManagerComponent } from './components/center-manager/center-manager.component';
import { EquipmentsListComponent } from './components/equipments-list/equipments-list.component';
import { InformationListComponent } from './components/information-list/information-list.component';
import { LocaleSelectComponent } from './components/locale-select/locale-select.component';
import { QuotesListComponent } from './components/quotes-list/quotes-list.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SideMenuContentComponent } from './components/side-menu-content/side-menu-content.component';
import { SideMenuTabComponent } from './components/side-menu-tab/side-menu-tab.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ToolbarActionsComponent } from './components/toolbar-actions/toolbar-actions.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { UserTabComponent } from './components/user-tab/user-tab.component';

@NgModule({
    declarations: [
        SideMenuComponent,
        ToolbarComponent,
        SideMenuContentComponent,
        SideMenuTabComponent,
        UserTabComponent,
        CenterManagerComponent,
        SearchInputComponent,
        EquipmentsListComponent,
        QuotesListComponent,
        InformationListComponent,
        ToolbarActionsComponent,
        CartListComponent,
        LocaleSelectComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        NgxSkeletonLoaderModule,
        MatSelectModule,
        FormsModule
    ],
    exports: [SideMenuComponent]
})
export class LayoutModule {}
