import { InformationLanguage } from "src/app/shared/interfaces/information.interface";

export const DEFAULT_CODE = 'en';
export const DEFAULT_LABEL = 'English';
export const FRENCH_CODE = 'fr'
export const ENGLISH_CODE = 'en'

export const LOCALES_LIST = [
    { code: ENGLISH_CODE, label: 'English' },
    { code: FRENCH_CODE, label: 'French' }
];

export function getLanguageEnum(code: string): InformationLanguage {
    switch (code) {
        case 'fr':
            return InformationLanguage.french;
        case 'en':
            return InformationLanguage.english;
        default:
            return InformationLanguage.multilingual;
    }
}