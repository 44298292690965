import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
    @Input() placeholder = 'Search...';
    @Output() search = new EventEmitter<string>();

    searchQuery = '';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSearch(event: any) {
        const query = event.target.value;
        this.search.emit(query);
    }
}
