import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageKey } from 'src/app/core/enums/storage-key.enum';
import { DEFAULT_CODE, DEFAULT_LABEL, LOCALES_LIST } from 'src/app/core/helpers/localization';



@Component({
  selector: 'app-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.scss']
})
export class LocaleSelectComponent implements OnInit {

  currentLocale: string = this.getLocaleLabel(DEFAULT_CODE);

  constructor(private translationService: TranslateService) {
    translationService.setDefaultLang(DEFAULT_CODE);
  }

  ngOnInit() {
    this.currentLocale = this.getLocaleLabel(localStorage.getItem(StorageKey.LANGUAGE) ?? DEFAULT_CODE);
    this.translationService.onLangChange.subscribe(() => {
      this.updateLocale();
    })
  }

  updateLocale() {
    this.currentLocale = this.getLocaleLabel(this.translationService.currentLang);
  }

  private getLocaleLabel(code: string): string {
    return LOCALES_LIST.find(locale => locale.code === code)?.label ?? DEFAULT_LABEL;
  }

  switchLocale(code: string) {
    this.translationService.use(code);
    this.showOptions = false;
    localStorage.setItem(StorageKey.LANGUAGE, code);
  }

  showOptions = false;

  showHide() {
    this.showOptions = !this.showOptions;
  }

  LOCALES_LIST = LOCALES_LIST;
}
