<ng-container *ngIf="haveCenters">
    <div style="margin-top: 16px" *ngIf="isRoot">
        <app-side-menu-tab
            *ngFor="let routeId of routesIds"
            [menuTab]="routes[routeId]"
            (click)="onNavigation()"
        ></app-side-menu-tab>
    </div>
    
    <div class="content" *ngIf="!isRoot">
        <div class="content-grid">
            <div class="header">
                <app-side-menu-header
                    [collapsedMode]="collapsedMode"
                    [currentRoute]="currentRoute"
                    [isRoot]="isRoot"
                ></app-side-menu-header>
            </div>
            <div class="body">
                <app-equipments-list *ngIf="isEquipment"></app-equipments-list>
                <app-information-list *ngIf="isInformation"></app-information-list>
                <app-cart-list *ngIf="isCart"></app-cart-list>
            </div>
            <div class="footer">
                <app-center-manager></app-center-manager>
                <app-user-tab></app-user-tab>
            </div>
        </div>
    </div>
</ng-container>
