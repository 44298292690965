import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-text-item',
    templateUrl: './text-item.component.html',
    styleUrls: ['./text-item.component.scss']
})
export class TextItemComponent {
    @Input() mainText1 = '';
    @Input() mainText2 = '';
    @Input() description = '';
    @Input() isSelected = false;
    @Output() subTabSelected = new EventEmitter();

    onClick() {
        this.subTabSelected.emit();
    }
}
