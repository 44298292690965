import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const ICONS = [
    { iconName: 'construction', iconPath: 'construction.svg' },
    {
        iconName: 'home',
        iconPath: 'home.svg'
    },
    { iconName: 'download', iconPath: 'download.svg' },
    {
        iconName: 'equipments',
        iconPath: 'equipments.svg'
    },
    { iconName: 'account_circle', iconPath: 'account_circle.svg' },
    {
        iconName: 'explosion',
        iconPath: 'explosion.svg'
    },
    { iconName: 'undo', iconPath: 'undo.svg' },
    {
        iconName: 'visibility',
        iconPath: 'visibility.svg'
    },
    { iconName: 'device_reset', iconPath: 'device_reset.svg' },
    {
        iconName: 'disabled_visible',
        iconPath: 'disabled_visible.svg'
    },
    {
        iconName: 'grain',
        iconPath: 'grain.svg'
    },
    {
        iconName: 'logout',
        iconPath: 'logout.svg'
    },
    {
        iconName: 'error',
        iconPath: 'error.svg'
    },
    {
        iconName: 'delete',
        iconPath: 'delete.svg'
    },
    {
        iconName: 'factory',
        iconPath: 'factory.svg'
    },
    {
        iconName: 'factory-active',
        iconPath: 'factory-active.svg'
    },
    {
        iconName: 'error-big',
        iconPath: 'error-big.svg'
    },
    {
        iconName: 'add_task',
        iconPath: 'add_task.svg'
    },
    {
        iconName: 'download',
        iconPath: 'download.svg'
    },
    {
        iconName: 'deployed_code',
        iconPath: 'deployed_code.svg'
    },
    {
        iconName: 'file_pdf',
        iconPath: 'file_pdf.svg'
    },
    {
        iconName: 'file_doc',
        iconPath: 'file_doc.svg'
    },
    {
        iconName: 'file_excel',
        iconPath: 'file_excel.svg'
    },
    {
        iconName: 'unknown',
        iconPath: 'file_unknown.svg'
    },
];

@NgModule({
    declarations: [],
    imports: [HttpClientModule]
})
export class MatIconRegistryModule {
    constructor(
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry
    ) {
        this.registerIcons();
    }

    private registerIcons(): void {
        ICONS.forEach(({ iconName, iconPath }) => {
            const iconUrl = this.generateIconUrl(iconPath);
            this.matIconRegistry.addSvgIcon(iconName, iconUrl);
        });
    }

    private generateIconUrl(iconPath: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(
            `/assets/icons/${iconPath}`
        );
    }
}
