import { Component, Input } from '@angular/core';
import { EquipmentHotspot } from '../../interfaces/equipment.interface';

@Component({
    selector: 'app-hotspot',
    templateUrl: './hotspot.component.html',
    styleUrls: ['./hotspot.component.scss']
})
export class HotspotComponent {
    @Input() item!: EquipmentHotspot;
}
