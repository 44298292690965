<ng-container *ngIf="(mode$ | async) == INFORMATION_ACTIONS_MODE">
    <div class="actions-container">
        <app-custom-button
            icon="exit_to_app"
            [text]="'Exit' | translate"
            color="white"
            type="link"
            fontColorMode="light"
            [borderRadius]="25"
            [styles]="{ border: '1px solid #00568C' }"
            (buttonClick)="exitInformationSelectedScreen()"
        ></app-custom-button>
        <app-custom-button
            icon="cloud_download"
            [text]="'Download' | translate"
            color="white"
            type="link"
            fontColorMode="light"
            [borderRadius]="25"
            [styles]="{ border: '1px solid #00568C' }"
            (buttonClick)="downloadInformationPdf()"
        ></app-custom-button>
    </div>
</ng-container>
<ng-container *ngIf="(mode$ | async) == EQUIPMENT_ACTIONS_MODE">
    <div class="actions-container">
        <app-custom-button
            icon="exit_to_app"
            [text]="'Exit' | translate"
            color="white"
            type="link"
            fontColorMode="light"
            [borderRadius]="25"
            [styles]="{ border: '1px solid #00568C' }"
            (buttonClick)="exitEquipmentInformationSelectedScreen()"
        ></app-custom-button>
        <app-custom-button
            icon="cloud_download"
            [text]="'Download' | translate"
            color="white"
            type="link"
            fontColorMode="light"
            [borderRadius]="25"
            [styles]="{ border: '1px solid #00568C' }"
            (buttonClick)="downloadEquipmentInformationPdf()"
        ></app-custom-button>
    </div>
</ng-container>