import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MenuTab } from '../../../interfaces/menu-tab';
import { Store } from '@ngrx/store';
import { selectedEntity, selectUrl } from 'src/app/store/root.selectors';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-side-menu-tab',
    templateUrl: './side-menu-tab.component.html',
    styleUrls: ['./side-menu-tab.component.scss']
})
export class SideMenuTabComponent {
    @Input() menuTab!: MenuTab;
    @Input() count: number | undefined;
    
    @Input() directLink = false;
    @Input() disableLink = false;
    currentUrl$: Observable<string>;
    selectedTabId$: Observable<string>;

    constructor(
        private store: Store,
    ) {
        this.currentUrl$ = store.select(selectUrl);
        this.selectedTabId$ = store.select(selectedEntity);
    }

    handleOnClick(): void {
        if (this.menuTab.onClick != null) {
            this.menuTab.onClick();
        }
    }
}
