import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getUserInfo, logout } from 'src/app/store/root.actions';
import { selectUserName } from 'src/app/store/root.selectors';

@Component({
    selector: 'app-user-tab',
    templateUrl: './user-tab.component.html',
    styleUrls: ['./user-tab.component.scss']
})
export class UserTabComponent {
    userName$: Observable<string>;

    constructor(private store: Store) {
        this.userName$ = this.store.select(selectUserName);
        this.store.dispatch(getUserInfo());
    }

    showOptions = false;

    handleClick() {
        this.showOptions = !this.showOptions;
    }

    logout() {
        this.showOptions = false;
        this.store.dispatch(logout());
    }
}
