import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-custom-slider',
    templateUrl: './custom-slider.component.html',
    styleUrls: ['./custom-slider.component.scss']
})
export class CustomSliderComponent {
    @Input() label = '';
    @Input() icon? = '';
    @Input() svgIcon? = '';
    @Input() fontSize?: number;
    @Input() iconSize?: number;
    @Input() minValue?: number = 0;
    @Input() color?: string;
    @Input() bold?: boolean = false;
    @Input() disabled?: boolean = false;
    @Input() value = 0;
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    onValueChange() {
        if (this.minValue && this.minValue > this.value) {
            this.value = this.minValue;
        }
        this.valueChange.emit(this.value);
    }
}
