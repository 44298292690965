import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-quantity-selector',
    templateUrl: './qty-selector.component.html',
    styleUrls: ['./qty-selector.component.scss']
})
export class QtySelectorComponent {
    private _quantity = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private increaseInterval: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private decreaseInterval: any;
    private readonly actionIntervalTime: number = 280;

    @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    set quantity(value: number) {
        if (this._quantity !== value) {
            this._quantity = value;
        }
    }

    get quantity(): number {
        return this._quantity;
    }

    private increase() {
        this.setDefaultIfNegative();
        this.quantity++;
        this.quantityChange.emit(this._quantity);
    }

    private decrease() {
        this.setDefaultIfNegative();
        if (this.quantity > 0) {
            this.quantity--;
            this.quantityChange.emit(this._quantity);
        }
    }

    private setDefaultIfNegative() {
        if (this._quantity < 0) {
            this._quantity = 0;
        }
    }

    startIncreasing() {
        this.increase();
        this.increaseInterval = setInterval(
            () => this.increase(),
            this.actionIntervalTime
        );
    }

    startDecreasing() {
        this.decrease();
        this.decreaseInterval = setInterval(
            () => this.decrease(),
            this.actionIntervalTime
        );
    }

    stop() {
        clearInterval(this.increaseInterval);
        clearInterval(this.decreaseInterval);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange(event: any) {
        if (!event.target.value || event.target.value <= 0) {
            this._quantity = 0;
        }
        this.quantityChange.emit(this._quantity);
    }
}
