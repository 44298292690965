<div class="custom-slider-container">
    <mat-icon
        *ngIf="icon"
        [class.disabled]="disabled"
        [ngStyle]="{
            'color': color,
            'width': iconSize ? iconSize+'px' : '',
            'height': iconSize ? iconSize+'px' : '',
            'font-size': iconSize ? iconSize+'px' : '',
        }"
    >{{ icon }}</mat-icon>
    <mat-icon
        *ngIf="svgIcon"
        [svgIcon]="svgIcon"
        [class.disabled]="disabled"
        [ngStyle]="{
            'color': color,
            'width': iconSize ? iconSize+'px' : '',
            'height': iconSize ? iconSize+'px' : '',
            'font-size': iconSize ? iconSize+'px' : '',
        }"
    ></mat-icon>

    <div
        *ngIf="label"
        class="slider-label"
        [class.disabled]="disabled"
        [ngStyle]="{
            'font-size': fontSize ? fontSize+'px' : '',
            'color': color,
            'font-weight': bold ? 'bold' : '',
        }"
    >{{ label }}</div>

    <input
        type="range"
        [min]="0"
        [max]="100"
        [ngStyle]="{'accent-color': color}"
        [(ngModel)]="value"
        [disabled]="disabled!"
        (input)="onValueChange()"
    >
</div>
