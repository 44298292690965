import { Actions, concatLatestFrom, createEffect, ofType, ROOT_EFFECTS_INIT } from "@ngrx/effects";
import { AnalyticsService } from "../core/services/analytics.service";
import { Injectable } from "@angular/core";
import { map, tap } from "rxjs";
import * as rootActions from 'src/app/store/root.actions';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { fromRoot } from "./root.selectors";
import { Store } from "@ngrx/store";

@Injectable()
export class AnalyticsEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private analyticsService: AnalyticsService
    ) { }


    initAnalytics$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ROOT_EFFECTS_INIT),
                map(() => {
                    this.analyticsService.init();
                })
            );
        },
        { dispatch: false }
    );

    identifyUserInAnalytics$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    rootActions.changeCenterSuccess,
                    rootActions.getUserInfoSuccess
                ),
                concatLatestFrom(() => this.store.select(fromRoot.selectUserInfo)),
                map(([, userInfo]) => {
                    const userId = userInfo?.email;
                    if (!userInfo || !userId) {
                        return;
                    }
                    this.analyticsService.setUser(userId, { ...userInfo });
                })
            );
        },
        { dispatch: false }
    );

    logPageView$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<RouterNavigatedAction>(ROUTER_NAVIGATED),
                tap((action) => {
                    const pageName = action.payload.routerState.url;
                    this.analyticsService.logPageView(pageName);
                })
            ),
        { dispatch: false }
    );


}
